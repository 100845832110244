<script>
  import { onMount } from "svelte";

  export let justify = "center";
  export let fillContainer = true;
  export let fill = null;
  export let delay = true;

  const attributes = {
    style: fill && `--fill: ${fill};`,
  };

  let show = delay ? false : true;
  onMount(() => {
    const timer = setTimeout(() => (show = true), 500);

    return () => clearTimeout(timer);
  });
</script>

{#if show}
  <div class="flex justify-{justify} items-center" class:w-full={fillContainer} class:h-full={fillContainer}>
    <svg class="spinner" viewBox="0 0 32 32" aria-hidden="true" focusable="false"
      ><path
        d="M6.5 23.5c-.7 0-1.4-.4-1.7-1C3.6 20.5 3 18.3 3 16 3 8.8 8.8 3 16 3s13 5.8 13 13c0 1.1-.9 2-2 2s-2-.9-2-2c0-5-4-9-9-9s-9 4-9 9c0 1.6.4 3.1 1.2 4.5.6 1 .2 2.2-.7 2.7-.4.2-.7.3-1 .3z"
        {...attributes}
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 16 16"
          to="360 16 16"
          dur=".5s"
          repeatCount="indefinite"
        /></path
      >
      <defs>
        <linearGradient
          id="light-gradient02-alt"
          x1="10.8016"
          y1="11.6658"
          x2="-1.95542"
          y2="2.82005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6F4CD2" />
          <stop offset="0.5625" stop-color="#BF14A2" />
          <stop offset="1" stop-color="#F73A1C" />
        </linearGradient>
        <linearGradient
          id="light-gradient02-alt"
          x1="1.66755"
          y1="1.3939"
          x2="11.5828"
          y2="12.8457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FA7250" />
          <stop offset="0.407227" stop-color="#FF1893" />
          <stop offset="1" stop-color="#A78AFF" />
        </linearGradient>
      </defs>
    </svg>
  </div>
{/if}

<style>
  .spinner {
    background-size: 22px 22px;
    display: inline-block;
    height: 22px;
    margin-right: 12px;
    width: 22px;
  }
  .spinner path {
    fill: var(--fill, url("#light-gradient02-alt"));
  }
  :global(.theme--dark) .spinner path {
    fill: var(--fill, url("#dark-gradient02-alt"));
  }
</style>
