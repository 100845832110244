<script>
  export let processor;

  const infoMapping = {
    ios: {
      text: "It appears you purchased Brave VPN on an iOS device. You'll need to visit the Apple App Store to complete your cancellation.",
      supportLink: "https://support.apple.com/en-us/HT202039",
      supportLinkText: "Visit Apple Support to cancel",
    },
    android: {
      text: "It appears you purchased Brave VPN on an Android device. You'll need to visit the Google Play Store to complete your cancellation.",
      supportLink: "https://support.google.com/googleplay/answer/7018481",
      supportLinkText: "Visit Google Play Help to cancel",
    },
  };

  const info = infoMapping[processor];
</script>

<p class="pb-8">
  {info?.text}
</p>

<a
  href={info?.supportLink}
  class="c:btn c:btn--filled col-span-2 w-full min-w-max max-w-none xs:w-auto sm:max-w-min md-sm:col-auto"
>
  {info?.supportLinkText}
  <svg class="o:icon c:btn__icon--r inline-block">
    <use href="/images/brave-icons.svg#arrow-right" />
  </svg>
</a>
