<script>
  import { SUBSCRIPTION_SERVICE_URL } from "$lib/Env";
  import { createEventDispatcher, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import PlanBanner from "./PlanBanner.svelte";
  import Spinner from "./Spinner.svelte";
  import ProcessorLabel from "./ProcessorLabel.svelte";
  import { getFormattedDate, getFormattedPrice, getUser } from "$lib/utils";

  /** @type {PlanDetails} */
  export let details;
  export let isTrial = false;
  export let status = "";
  export let expiresAt = "";
  export let orderId = "";
  export let subscriptionId = "";
  export let productDomain = "";
  export let checkoutSession = "";
  export let handleRecovery = null;
  export let disableGoToAndPayments = undefined;
  export let credentialsPresent = false;
  export let currentBrowserSupported;
  export let paymentProcessor;
  export let pricePaid;

  const expiresAtString = expiresAt ? getFormattedDate(expiresAt) : "";

  const { name, baseName, price, icon, usesNativeSDK, browserSupport } = details;

  const eventTypes = {
    PAYMENT_CLICK: "paymentClick",
    CANCEL_PENDING: "cancelPending",
    CANCEL: "cancel",
    RENEW: "renew",
    OPEN_PRODUCT: "openProduct",
  };

  const isLoading = {};

  const dispatch = createEventDispatcher();
  const emitClick = (type, showLoader) => (e) => {
    e.currentTarget.disabled = true;
    isLoading[type] = showLoader && !isLoading[type];
    dispatch(type, {
      status: status,
      order_id: orderId,
      subscription_id: subscriptionId,
      product_id: details.id,
      session_id: checkoutSession,
      payment_processor: paymentProcessor,
      price_paid: pricePaid,
      ...details,
      cb: () => (isLoading[type] = false),
    });
  };

  $: hasBanner = isTrial || credentialsPresent || ["pending", "canceled"].includes(status);

  /**
   * Button should ONLY display if:
   * A. plan is paid or canceled
   * B. AND in a supported browser
   * C. AND if plan is VPN and credentials are not present
   */
  $: showGoToButton =
    ["paid", "canceled"].includes(status) && currentBrowserSupported && !(usesNativeSDK && credentialsPresent);

  const isLinkedPurchase = ["android", "ios"].includes(paymentProcessor);

  const user = getUser();

  const openProduct = async () => {
    isLoading[eventTypes.OPEN_PRODUCT] = !isLoading[eventTypes.OPEN_PRODUCT];
    handleRecovery &&
      (await handleRecovery(user, orderId, subscriptionId, productDomain, usesNativeSDK, currentBrowserSupported));
    isLoading[eventTypes.OPEN_PRODUCT] = false;
  };

  let show = false;
  onMount(() => {
    show = true;
  });
</script>

{#if show}
  <article
    in:fade={{ duration: 200 }}
    class="plan theme--dark overflow-hidden rounded-lg bg-dark-02 p-6 text-white"
    class:pt-0={hasBanner}
  >
    {#if status === "error"}
      <PlanBanner error classes="mb-6 -mx-6">There was a problem with your payment method</PlanBanner>
    {:else if status === "pending"}
      <PlanBanner warning classes="mb-6 -mx-6">Complete your purchase to begin using {name}</PlanBanner>
    {:else if status === "canceled"}
      <PlanBanner warning classes="mb-6 -mx-6"
        >Your subscription has been canceled. Access until {expiresAtString}.</PlanBanner
      >
    {:else if credentialsPresent}
      <PlanBanner success classes="mb-6 -mx-6">You have active credentials loaded!</PlanBanner>
    {/if}

    <div class="flex items-stretch">
      <img
        class="mr-2 hidden self-start sm:block md-sm:mr-6"
        width="120"
        height="120"
        src={`/icons/${icon}`}
        alt={`${name} icon`}
      />
      <div class="flex flex-grow flex-col justify-between">
        <header class="mb-4 items-center xs:mb-3 sm:flex">
          <h1 class="pr-3 text-h5 xxs:text-h4">{name}</h1>
          <span class="ml-auto text-h5 font-semibold xxs:text-h4"
            >{getFormattedPrice(pricePaid || price)}<span class="text-sm text-dark-02">/month</span></span
          >
        </header>
        <footer
          class="flex w-full flex-col place-content-end items-center justify-end gap-x-4 gap-y-3 self-center xs:grid xs:grid-flow-col-dense xs:self-end md-sm:grid-flow-col-dense"
          class:justify-between={isLinkedPurchase}
        >
          {#if showGoToButton}
            <button
              disabled={disableGoToAndPayments || isLoading[eventTypes.OPEN_PRODUCT]}
              class="c:btn c:btn--filled col-span-2 w-full min-w-max max-w-none xs:order-last xs:w-auto xs:max-w-min md-sm:col-auto"
              class:loading={isLoading[eventTypes.OPEN_PRODUCT]}
              on:click={openProduct}
            >
              {#if isLoading[eventTypes.OPEN_PRODUCT]}
                <Spinner fill="currentColor" delay={false} fillContainer={false} />
                Loading...
              {:else if usesNativeSDK}
                Refresh {baseName}
                <svg class="o:icon c:btn__icon--r inline-block">
                  <use href="/images/brave-icons.svg#refresh" />
                </svg>
              {:else}
                Go to {baseName}
                <svg class="o:icon c:btn__icon--r inline-block">
                  <use href="/images/brave-icons.svg#arrow-right" />
                </svg>
              {/if}
            </button>
          {:else if status === "pending"}
            <button
              class="c:btn c:btn--text"
              disabled={isLoading[eventTypes.CANCEL_PENDING]}
              on:click={emitClick(eventTypes.CANCEL_PENDING, true)}
            >
              {#if isLoading[eventTypes.CANCEL_PENDING]}
                <Spinner fill="currentColor" delay={false} fillContainer={false} />
                Loading...
              {:else}
                Cancel purchase
              {/if}
            </button>
            <button
              class="c:btn c:btn--filled col-span-2 w-full min-w-max max-w-none xs:order-last xs:w-auto md-sm:col-auto"
              class:loading={isLoading[eventTypes.PAYMENT_CLICK]}
              disabled={disableGoToAndPayments || isLoading[eventTypes.PAYMENT_CLICK]}
              on:click={emitClick(eventTypes.PAYMENT_CLICK, true)}
            >
              {#if isLoading[eventTypes.PAYMENT_CLICK]}
                <Spinner fill="currentColor" delay={false} fillContainer={false} />
                Loading...
              {:else}
                Complete payment
              {/if}
            </button>
          {/if}
          {#if isLinkedPurchase}
            <ProcessorLabel ref="processor-label" processor={paymentProcessor} />
          {/if}
          {#if status === "canceled"}
            <button class="c:btn c:btn--text" disabled={disableGoToAndPayments} on:click={emitClick(eventTypes.RENEW)}
              >Renew plan</button
            >
          {:else if status !== "pending"}
            {#if !isLinkedPurchase}
              <a href={`${SUBSCRIPTION_SERVICE_URL}/subscribers/billingsession`} class="c:btn c:btn--text"
                >Edit payment</a
              >
            {/if}
            <button class="c:btn c:btn--text" on:click={emitClick(eventTypes.CANCEL)}>Cancel plan</button>
          {/if}
        </footer>
      </div>
    </div>
    {#if !browserSupport.isSupported}
      <p class="mt-4 text-tiny text-dark-02">
        {browserSupport.message}
      </p>
    {/if}
  </article>
{/if}

<style>
  .plan {
    @apply mb-4;
  }
  .plan:last-of-type {
    @apply mb-0;
  }

  .plan :global([ref="processor-label"]) {
    order: -99999;
    margin-right: auto;
    margin-bottom: 10px;
  }

  @screen xs {
    .plan :global([ref="processor-label"]) {
      order: 0;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
</style>
