<script context="module" lang="ts">
  import { writable } from "svelte/store";

  export const plans = writable<Plan[]>([]);
  export const isUnsuportedBrowser = writable(false);
  export const isNotPurchased = writable(null);
  export const isFetching = writable(false);
  export const isRefreshing = writable(true);
  export const tooManyRecoveries = writable(null);
  export const orderNotPaid = writable(null);
  export const hideNotificationBanner = writable(false);
</script>

<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import { fade, slide } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import DOMPurify from "isomorphic-dompurify";
  import { APP_NAME } from "$lib/Env";
  import { page } from "$app/stores";
  import { cancel, getSubscriber, logout } from "$lib/subscription-service";
  import { propagateSafeParams, unloadUser } from "$lib/utils";
  import "@fontsource/poppins/400.css";
  import "@fontsource/poppins/600.css";
  import NavLink from "$lib/components/NavLink.svelte";
  import NotificationBanner from "$lib/components/NotificationBanner.svelte";
  import Loading from "$lib/components/Loading.svelte";
  import svgGradients from "../images/svg-gradients.svg?raw";
  import { globalIntents, isIntent } from "$lib/intents";
  import { getPsPromise } from "$lib/payments-service";
  import type { LayoutData } from "./$types";
  import "../app.scss";
  import { handleRecovery } from "./(app)/account/+page.svelte";
  import { browser } from "$app/environment";
  import { beforeNavigate } from "$app/navigation";

  const trustedSvgGradients = DOMPurify.sanitize(svgGradients, { RETURN_TRUSTED_TYPE: true });

  $: pathname = $page.url.pathname;

  export let data: LayoutData;
  $: ({ orderId, intent, productId, productKey, user, uxMode } = data);
  $: loggedIn = !!user?.subscriber_id;

  let mobileNavShown = false;

  // TODO: refactor notification system
  $: paymentError = $plans.find((p) => p.status && ["pending"].includes(p.status));
  $: currentPlan = isIntent(intent, ["provision", "recover"])
    ? $plans.find((p) => p.orderId === orderId && p.status === "paid") ||
      $plans.find((p) => p.details.id === productId && p.status === "paid")
    : $plans.find((p) => p.orderId === $tooManyRecoveries) || $plans.find((p) => p.orderId === $orderNotPaid);

  $: notificationPlan = $isNotPurchased || paymentError || currentPlan;

  let verifyLoginInterval: number;

  const handleLogout = async () => {
    try {
      await logout();
    } catch (_) {}

    // Clear user from sessionStorage
    unloadUser();

    // Determine where to redirect after logout
    let redirectUrl = "/";
    if (isIntent(intent, globalIntents)) {
      redirectUrl = `/?intent=${intent}&product=${productKey}`;
    }

    // Clear verification interval
    clearInterval(verifyLoginInterval);

    // Intentionally not using SvelteKit's `goto` in order to force a full refresh and clear console/network logs
    location.href = propagateSafeParams(location.href, redirectUrl).toString();
  };

  const verifyLogin = () => {
    getSubscriber().catch(() => {
      handleLogout();
    });
  };

  beforeNavigate(({ to, from }) => {
    const destWithParams = propagateSafeParams(from.url, to.url);
    to.url.search = destWithParams.search;
  })

  onMount(async () => {
    if (user) {
      // Verify logged in status
      verifyLogin();

      // Poll for logged in status
      verifyLoginInterval = setInterval(verifyLogin, 60_000);

      // Update stores
      if (user) {
        const ps = await getPsPromise();
        plans.set(user.subscriptions ? await ps.refreshPlans(user) : []);

        // NOTE: to keep or not to keep
        isRefreshing.set(false);
      }
    }
  });

  onDestroy(() => clearInterval(verifyLoginInterval));

  const handlePaymentAction = async (e) => {
    const ps = await getPsPromise();
    ps.checkout(e.detail.session_id);
  };

  const handleCancelPending = async (e) => {
    const subscriptionId = e.detail?.plan?.subscriptionId;
    unloadUser();
    if (subscriptionId) {
      await cancel(subscriptionId);
    }
    const ps = await getPsPromise();
    $plans = await ps.refreshPlans(user);
    e.detail?.cb();
  };

  const handleRecoveryClick = async (e) => {
    const plan = e.detail?.plan as Plan;
    await handleRecovery(
      user,
      orderId,
      plan.subscriptionId,
      plan.productDomain,
      plan.details.usesNativeSDK,
      plan.currentBrowserSupported
    );
    e.detail?.cb();
  };
</script>

<svelte:head>
  <title>{APP_NAME}</title>
  {#if pathname !== "/"}
    <link rel="preload" href="/images/brave-icons.svg" as="image" />
  {/if}
</svelte:head>

{@html trustedSvgGradients}

<header
  class="relative mx-auto mb-4 flex lg:min-h-[120px] max-w-screen-xl items-center justify-end bg-black px-8 py-3 lg:mb-0 lg:bg-transparent lg:pb-11 lg:pt-9"
>
  <img class="mr-auto" src="/images/brave-premium-logo.svg" alt="Brave Premium" />
  {#if loggedIn}
    <nav class="theme--ghost hidden lg:block">
      <a class="c:btn c:btn--text" href="https://support.brave.com/">Support</a>
      <button class="c:btn c:btn--ghost ml-9" on:click={handleLogout}>{loggedIn ? "Log out" : "Log in"}</button>
    </nav>

    <button
      aria-label="toggle site menu"
      class="mobile-nav__toggle c:btn c:btn--icon theme--dark block lg:hidden"
      class:active={mobileNavShown}
      on:click={() => (mobileNavShown = !mobileNavShown)}
    >
      <svg class="o:icon" viewBox="0 0 24 18">
        <path
          id="hBar-1"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23 2H1C0.448 2 0 1.552 0 1C0 0.448 0.448 0 1 0H23C23.552 0 24 0.448 24 1C24 1.552 23.552 2 23 2Z"
        />
        <path
          id="hBar-2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23 10H1C0.448 10 0 9.552 0 9C0 8.448 0.448 8 1 8H23C23.552 8 24 8.448 24 9C24 9.552 23.552 10 23 10Z"
        />
        <path
          id="hBar-3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1 16H23C23.552 16 24 16.448 24 17C24 17.552 23.552 18 23 18H1C0.448 18 0 17.552 0 17C0 16.448 0.448 16 1 16Z"
        />
      </svg>
    </button>
  {/if}

  {#if mobileNavShown}
    <nav
      transition:slide={{ duration: 100, easing: quintOut }}
      class="o:container absolute left-1/2 top-full z-50 block -translate-x-1/2 lg:hidden"
    >
      <ul class="main mobile-nav theme--dark flex flex-col border-t-4 border-interactive-02 bg-black p-9 pt-6">
        <li class="mobile-nav__item">
          <a class="c:btn c:btn--text w-max" href="https://support.brave.com/">Support</a>
        </li>
        {#if loggedIn && uxMode !== "mobile"}
          <li class="mobile-nav__item">
            <a class="c:btn c:btn--text w-max" href="/account/">Account</a>
          </li>
          <li class="mobile-nav__item">
            <a class="c:btn c:btn--text w-max" href="/plans/">Plans</a>
          </li>
        {/if}
        <li class="mobile-nav__item">
          <button class="c:btn c:btn--outline w-max" on:click={handleLogout}>{loggedIn ? "Log out" : "Log in"}</button>
        </li>
      </ul>
    </nav>
  {/if}
</header>

{#if !browser || $isFetching || (pathname !== "/" && $plans.length <= 0)}
  <div class="o:container site-container flex flex-wrap content-start items-start justify-center">
    <Loading />
  </div>
{/if}

{#if browser && (pathname === "/" || (pathname !== "/" && $plans.length > 0))}
  <div
    class:hidden={$isFetching}
    class="o:container site-container flex flex-wrap content-start items-start justify-center"
  >
    {#if notificationPlan && !$hideNotificationBanner && pathname !== "/"}
      {#key notificationPlan}
        {#if $tooManyRecoveries}
          <NotificationBanner plan={notificationPlan} isTooManyRecoveries={true} />
        {:else if $orderNotPaid}
          <NotificationBanner plan={notificationPlan} on:paymentClick={handlePaymentAction} isUnpaid={true} />
        {:else if $isUnsuportedBrowser}
          <NotificationBanner plan={notificationPlan} isUnsuportedBrowser={true} />
        {:else if $isNotPurchased}
          <NotificationBanner plan={notificationPlan} isNotPurchased={true} />
        {:else if notificationPlan.currentBrowserSupported}
          <NotificationBanner
            plan={notificationPlan}
            on:refreshClick={handleRecoveryClick}
            on:paymentClick={handlePaymentAction}
            on:cancelPendingClick={handleCancelPending}
          />
        {/if}
      {/key}
    {/if}
    {#if pathname !== "/" && uxMode !== "mobile"}
      <aside in:fade={{ duration: 300 }} class="navigation c:box px-0 py-6">
        <ul>
          <li class="nav-item-wrapper">
            <NavLink to="/account" icon="account">Account</NavLink>
          </li>
          <li class="nav-item-wrapper">
            <NavLink to="/plans" icon="plans">Plans</NavLink>
          </li>
        </ul>
      </aside>
    {/if}
    <main in:fade={{ duration: 300 }} class="main" class:!mx-auto={uxMode === "mobile"}>
      <slot />
    </main>
  </div>
{/if}

<footer class="site-footer theme--ghost">
  <div class="site-footer__inner">
    <span class="site-footer__item">2024 © Brave Software, Inc.</span>
    <a class="site-footer__item" href="https://brave.com/terms-of-use/" target="_blank" rel="noopener noreferrer"
      >Terms of Use</a
    >
    <a class="site-footer__item" href="https://brave.com/privacy/browser/" target="_blank" rel="noopener noreferrer"
      >Privacy Policy</a
    >
    <a class="site-footer__item" href="https://hackerone.com/brave" target="_blank" rel="noopener noreferrer"
      >Report a security issue</a
    >
  </div>
</footer>

<style lang="scss">
  :global(html) {
    background: url("/images/swoosh.svg"), linear-gradient(121.55deg, #3c0ba9 17.34%, #930fa8 113.19%);
    background-repeat: no-repeat;
    background-position: bottom, center;
    background-size: 100%, auto;
    min-height: 100vh;
    background-blend-mode: normal, normal;
  }
  :global(body) {
    background: url("/images/flare.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    min-height: 100vh;
    background-blend-mode: normal;
  }

  .navigation {
    @apply w-[268px];
    @apply hidden;

    @screen lg {
      display: block;
    }
  }

  .nav-item-wrapper {
    @apply relative;
    @apply px-8;
  }
  :global(.main) {
    @apply mx-auto;
    @apply min-h-full;
    @apply w-full;
    max-width: 674px;
  }
  :global(.main):not(:first-child) {
    @screen lg {
      @apply ml-6;
    }
  }

  .site-container {
    /* full height, minus header and footer */
    min-height: calc(100vh - 120px - 61px);
    @apply pb-4;
  }
  .mobile-nav__toggle {
    svg {
      transform: rotate(0);
      transition: transform 0.25s ease-out;
    }
    path {
      transform-origin: center;
      transform: rotate(0);
      opacity: 1;
      transition:
        transform 0.25s ease-out,
        opacity 0.15s ease-out;
    }

    &.active {
      svg {
        transform: rotate(-45deg);
      }
      #hBar-1 {
        transform: rotate(90deg) translateY(8px);
      }
      #hBar-2 {
        opacity: 0;
      }
      #hBar-3 {
        transform: translateY(-8px);
      }
    }
  }
  .mobile-nav {
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.75);
  }
  .mobile-nav__item:not(:last-child) {
    @apply pb-4;
  }
  .mobile-nav__item .c\:btn--text {
    @apply text-white;
  }
  .site-footer {
    @apply border-t border-gray-100 border-opacity-30;
  }
  .site-footer__inner {
    @apply mx-auto max-w-screen-xl;
    @apply px-8;
    @apply flex flex-col items-center justify-center md:flex-row;
    @apply min-h-[60px];

    @screen md {
      @apply flex-row;
    }
  }

  .site-footer__item {
    @apply text-sm text-white;
    @apply py-3;
    @apply border-gray-100 border-opacity-30;

    @screen md {
      @apply border-r px-4 py-0;
    }

    &:first-child {
      @apply pl-0;
      @apply order-last;

      @screen md {
        @apply order-none;
      }
    }
    &:last-child {
      @apply border-0;
    }
  }
</style>
