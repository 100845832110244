<script>
  export let error = false;
  export let success = false;
  export let warning = false;
  export let classes = "mb-8 -mx-8"; // default styles for PlanDescription
</script>

<div
  class:bg-success-icon={success}
  class:bg-warning-border={warning}
  class:bg-error-icon={error}
  class={`px-4 py-2 text-center text-sm font-semibold tracking-wide ${classes}`}
  class:uppercase={success}
  class:text-white={success || error}
  class:text-light-01={warning}
>
  <slot />
</div>
