<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import { goto } from "$app/navigation";
  import Spinner from "./Spinner.svelte";

  /** @type {Plan} */
  export let plan;
  export let isTooManyRecoveries = false;
  export let isUnsuportedBrowser = false;
  export let isNotPurchased = false;
  export let isUnpaid = false;

  const { details: planDetails, checkoutSession, productDomain } = plan;

  const isVPN = planDetails.key === "vpn";
  const isLeo = planDetails.key === "leo";

  const isError = plan.status === "error" || isUnsuportedBrowser;
  const isPending = plan.status === "pending";
  const isSuccess = plan.status === "paid";
  const isSuccessButNoCredentials = isSuccess && planDetails.usesNativeSDK && !plan.credentialsPresent;

  const tooManyRecoveriesText = {
    heading: `Too many recovery attempts`,
    body: `It looks like you’re having trouble authenticating ${planDetails.name} on a new device. To protect the security of your account, we’ve temporarily restricted access. For now, please try accessing ${planDetails.name} from another device that’s already authenticated. You can also try authenticating this new device again in twenty-four hours, or contact Brave support.`,
  };

  const errorText = {
    heading: `There was a problem with your payment`,
    body: `Your payment of ${planDetails.cost}/month for  ${planDetails.name} did not go through. Access to this subscription will pause until the payment issue is resolved. Edit your payment method to continue using ${planDetails.name}.`,
    buttonText: "Edit payment method",
  };
  const pendingText = {
    heading: `Complete your purchase to begin using ${planDetails.name}`,
    body: `Your payment for ${planDetails.name} is not complete. This could be due to a network issue with Stripe (the payment provider), or something else. Click "Complete payment" to visit Stripe and finish the purchase.`,
    buttonText: "Complete payment",
  };
  const successText = {
    heading: `Almost time to ${planDetails.actionPhrase}...`,
    body: `We're verifying your access to ${planDetails.name}. it should just take a moment. If you're not automatically redirected, click "Return to ${planDetails.baseName}."`,
    buttonText: `Return to ${planDetails.baseName}`,
  };

  const vpnSuccessText = {
    heading: `Success!`,
    body: `Your Brave VPN credentials are now loaded on this device. To start using Brave VPN, click the "VPN" button in the Brave browser toolbar.`,
  };

  const leoSuccessText = {
    heading: `Success!`,
    body: `Your Brave Leo credentials are now loaded on this device. To start using Brave Leo, type your query in the address bar and select the "Ask Leo" option. You can also find Leo in the Sidebar.`,
  };

  const nativeSDKSuccessButNoCreds = {
    heading: `Success!`,
    body: `Your subscription is confirmed. Final step, click the 'Refresh' button to load your credentials and begin using ${planDetails.name}.`,
  };

  const notBraveText = {
    heading: `Whoops! Looks like you're not using a supported browser.`,
    body:
      planDetails.browserSupport.message ||
      `In order to use ${planDetails.name}, you'll have to use Brave Browser. Need to install Brave Browser?`,
    buttonText: `Download Brave Browser`,
  };

  const notPurchasedText = {
    heading: `We couldn't find a subscription associated with your email address.`,
    buttonText: `Buy now`,
  };

  const unpaidText = {
    heading: `We couldn't recover your credentials for ${planDetails.name} since the subscription is not paid.`,
    buttonText: "Complete payment",
  };

  let content = {};
  if (isTooManyRecoveries) content = tooManyRecoveriesText;
  else if (isUnpaid) content = unpaidText;
  else if (isUnsuportedBrowser) content = notBraveText;
  else if (isNotPurchased) content = notPurchasedText;
  else if (isError) content = errorText;
  else if (isPending) content = pendingText;
  else if (isSuccessButNoCredentials) content = nativeSDKSuccessButNoCreds;
  else if (isSuccess && isVPN) content = vpnSuccessText;
  else if (isSuccess && isLeo) content = leoSuccessText;
  else if (isSuccess) content = successText;

  const dispatch = createEventDispatcher();
  let isPaymentActionLoading = false;
  let isRefreshActionLoading = false;
  let isCancelActionLoading = false;

  const paymentAction = () => {
    isPaymentActionLoading = !isPaymentActionLoading;
    dispatch("paymentClick", {
      session_id: checkoutSession,
      cb: () => (isPaymentActionLoading = false),
    });
  };

  const cancelPending = () => {
    isCancelActionLoading = !isCancelActionLoading;
    dispatch("cancelPendingClick", {
      plan: plan,
      cb: () => (isCancelActionLoading = false),
    });
  };

  const refreshCredentials = () => {
    isRefreshActionLoading = !isRefreshActionLoading;
    dispatch("refreshClick", {
      plan: plan,
      cb: () => (isRefreshActionLoading = false),
    });
  };

  const returnToProduct = () => {
    location.replace(`https://${productDomain}`);
  };

  const tryAgain = () => {
    goto(`/account/?intent=recover&product=${planDetails.key}`);
  };

  const buyNow = () => {
    goto(`/?intent=checkout&product=${planDetails.key}`);
  };

  const downloadBrave = () => {
    location.assign("https://brave.com/download/");
  };

  let buttonAction;
  if (isTooManyRecoveries) {
    buttonAction = tryAgain;
  } else if (isUnsuportedBrowser) {
    buttonAction = downloadBrave;
  } else if (isNotPurchased) {
    buttonAction = buyNow;
  } else if (isSuccess) {
    buttonAction = returnToProduct;
  } else {
    buttonAction = paymentAction;
  }

  let show = false;
  onMount(() => {
    show = true;
  });
</script>

{#if show}
  <aside
    class="notice c:box"
    class:noCTA={isSuccess && !isSuccessButNoCredentials && (isVPN || isLeo) && !isError}
    class:error={isError || isNotPurchased || isUnpaid}
    class:warning={(!isUnpaid && isPending) || isTooManyRecoveries}
    class:success={isSuccess && !isError && !isTooManyRecoveries}
    in:slide={{ delay: 250, duration: 300, easing: quintOut }}
  >
    <div class="notice__sidebar">
      <svg class="o:icon">
        {#if isSuccess}
          <use href="/images/brave-icons.svg#check-circle" />
        {:else}
          <use href="/images/brave-icons.svg#info" />
        {/if}
      </svg>
    </div>
    <div class="notice__content">
      <div class="notice__body">
        <h1 class="notice__header">{content.heading}</h1>
        {#if content.body}
          <p>{content.body}</p>
        {/if}
      </div>
      <div class="notice__actions">
        {#if !isTooManyRecoveries && !((isVPN || isLeo) && isSuccess)}
          <button
            class="c:btn c:btn--filled mb-2"
            class:loading={isPaymentActionLoading}
            disabled={isPaymentActionLoading}
            on:click={buttonAction}
          >
            {#if isPaymentActionLoading}
              <Spinner fill="currentColor" delay={false} fillContainer={false} />
              Loading...
            {:else}
              {content.buttonText}
            {/if}
          </button>
        {/if}
        {#if isError || isPending || isTooManyRecoveries || isNotPurchased}
          <a href="https://support.brave.com/hc/en-us/requests/new" class="c:btn c:btn--outline"
            >Contact Brave support</a
          >
        {/if}
        {#if isPending && !isUnpaid && !isTooManyRecoveries}
          <button
            class="c:btn c:btn--text mt-2"
            class:loading={isCancelActionLoading}
            disabled={isCancelActionLoading}
            on:click={cancelPending}
          >
            {#if isCancelActionLoading}
              <Spinner fill="currentColor" delay={false} fillContainer={false} />
              Loading...
            {:else}
              Dismiss
            {/if}
          </button>
        {/if}
        {#if !isUnsuportedBrowser && isSuccessButNoCredentials}
          <button
            class="c:btn c:btn--filled mb-2"
            class:loading={isRefreshActionLoading}
            disabled={isRefreshActionLoading}
            on:click={refreshCredentials}
          >
            {#if isPaymentActionLoading}
              <Spinner fill="currentColor" delay={false} fillContainer={false} />
              Loading...
            {:else}
              Refresh
            {/if}
          </button>
        {/if}
      </div>
    </div>
  </aside>
{/if}

<style lang="scss">
  .notice {
    @apply relative;
    @apply overflow-hidden;
    @apply mb-4 w-full;
    @apply max-w-[675px] lg:max-w-none;
    @apply p-0;
    @apply flex;
  }
  .notice__sidebar {
    @apply w-16;
    @apply flex-shrink-0;
    @apply flex justify-center;
    @apply py-6;

    & .o\:icon {
      --size: 24px;
    }
  }
  .notice__content {
    @apply flex flex-wrap;
    @apply w-full;
    @apply p-8;
  }
  .notice__header {
    @apply text-base;
    @apply pb-6;
  }
  .notice__body {
    @apply w-full md:w-auto;
    @apply max-w-[488px];
    @apply text-sm;
  }
  .notice.noCTA .notice__body {
    @apply max-w-3xl;
  }
  .notice__actions {
    @apply flex flex-col;
    @apply flex-shrink-0;
    @apply self-end;
    @apply ml-auto mt-4;
    @apply xxs:pl-6;
    @apply border-gray-100 lg:border-l;
  }

  .error {
    .notice__header {
      @apply text-error-icon;
    }
    .notice__sidebar {
      @apply bg-error-border;
      & .o\:icon {
        --color: #ffffff;
      }
    }
  }
  .warning {
    .notice__header {
      @apply text-light-01;
    }
    .notice__sidebar {
      @apply bg-warning-border;
      & .o\:icon {
        --color: them("colors.text.light-02");
      }
    }
  }
  .success {
    .notice__header {
      @apply text-success-icon;
    }
    .notice__sidebar {
      @apply bg-success-icon;
      & .o\:icon {
        --color: #ffffff;
      }
    }
  }
</style>
