<script>
  export let ref;
  export let processor;

  const labelMap = {
    ios: "iOS",
    android: "Android",
  };
</script>

<span
  {ref}
  class="flex max-w-min items-center whitespace-nowrap rounded bg-[#585C6D] p-1 text-xs leading-none text-[#F4F6F8]"
>
  <svg class="o:icon mr-1 block flex-shrink-0 fill-current">
    <use href={`/images/brave-icons.svg#${processor}`} />
  </svg>
  Purchased <wbr />on {labelMap[processor]}
</span>

<style>
</style>
