<script context="module" lang="ts">
  export const handleRecovery = async (
    user: User,
    orderId: string,
    subscriptionId: string,
    productDomain: string,
    usesNativeSDK: boolean,
    currentBrowserSupported: boolean
  ) => {
    const ps = await getPsPromise();
    try {
      if (usesNativeSDK) {
        if (currentBrowserSupported) {
          await ps.recoverCredsIfRequired(orderId, subscriptionId);
          plans.set(await ps.refreshPlans(user));
        } else {
          isUnsuportedBrowser.set(true);
        }
      } else {
        await ps.deleteRemoteCredsIfNeccessary(orderId, subscriptionId);
        productDomain && location.assign(getRecoveryURL(productDomain, orderId));
      }
    } catch (e) {
      console.error(e.message);
      if (e.status === 429 && e.message?.startsWith("Can not recover")) {
        tooManyRecoveries.set(orderId);
      } else if (e.status === 429) {
        // Rate limited
        // TODO: tell user to try again in a few minutes
      } else if (e.message?.startsWith("Order not paid")) {
        orderNotPaid.set(orderId);
      } else {
        // TODO: tell user that something went wrong and include copyable error output
      }
    }
  };
</script>

<script lang="ts">
  import { onMount, type ComponentEvents } from "svelte";
  import { APP_NAME } from "$lib/Env";
  import { unloadUser, getRecoveryURL } from "$lib/utils";
  import { cancel } from "$lib/subscription-service";
  import Table from "$lib/components/Table.svelte";
  import MyPlan from "$lib/components/MyPlan.svelte";
  import Spinner from "$lib/components/Spinner.svelte";
  import CancelView from "$lib/components/CancelView.svelte";
  import CancelConfirmedView from "$lib/components/CancelConfirmedView.svelte";
  import { getPsPromise } from "$lib/payments-service";
  import {
    plans,
    isRefreshing,
    isUnsuportedBrowser,
    isNotPurchased,
    tooManyRecoveries,
    isFetching,
    orderNotPaid,
  } from "../../+layout.svelte";
  import type { PageData } from "./$types";

  export let data: PageData;
  $: ({ orderId, intent, productId, user } = data);

  onMount(async () => {
    const ps = await getPsPromise();

    if (intent === "provision" && orderId) {
      try {
        const order: Plan = $plans.find((p) => p.orderId === orderId);

        if (order) {
          if (!order.currentBrowserSupported) {
            $isUnsuportedBrowser = true;
          } else if (!order.credentialsPresent) {
            $isFetching = true;
            await ps.provisionOrder(order);
            $plans = await ps.refreshPlans(user);
            $isFetching = false;
          }

          if (order.status === "paid" && !order.details.usesNativeSDK) {
            const productDomain = order.productDomain;
            productDomain && location.assign(getRecoveryURL(productDomain, orderId));
          }
        }
      } catch (e) {
        console.log(e);
      }
    } else if (intent === "recover" && productId) {
      const planToRecover = $plans.find((p) => p.details.id === productId) as Plan;
      let {
        orderId,
        subscriptionId,
        productDomain,
        details: { usesNativeSDK, browserSupport },
        currentBrowserSupported,
      } = planToRecover;
      // TODO: unify browser support logic into plans.js and away from payment-services.js
      if (!browserSupport.isSupported && currentBrowserSupported) {
        $isUnsuportedBrowser = true;
      } else if (orderId && subscriptionId) {
        if (usesNativeSDK) {
          $isFetching = true;
        }
        await handleRecovery(user, orderId, subscriptionId, productDomain, usesNativeSDK, currentBrowserSupported);
        $isFetching = false;
      } else {
        $isNotPurchased = planToRecover;
      }
    }
  });

  let view = "main";
  let currentPlan: any = {}; // TODO: implement proper typing
  const handleViewChange = (nextView: string) => (e?: ComponentEvents<MyPlan>["cancel"]) => {
    scrollTo(0, 0);

    view = nextView;

    if (e && e.type === "cancel") {
      currentPlan = e.detail;
    }
  };

  const cancelPlan = async (e) => {
    const subscriptionId = currentPlan.subscription_id || e.detail.subscription_id;
    const status = currentPlan.status || e.detail.status;
    unloadUser();
    await cancel(subscriptionId);

    if (status === "pending") {
      const ps = await getPsPromise();
      $plans = await ps.refreshPlans(user);
    } else {
      handleViewChange("cancelConfirmed")();
    }

    e.detail?.cb();
  };

  const handleRenew = async (e) => {
    const ps = await getPsPromise();
    await ps.purchasePlanIfNecessary(e.detail.product_id, e.detail.subscription_id);
  };

  const handlePaymentAction = async (e) => {
    const ps = await getPsPromise();
    ps.checkout(e.detail.session_id);
  };

  const reloadAccount = async () => {
    const ps = await getPsPromise();
    $plans = await ps.refreshPlans(user);
    handleViewChange("main")();
  };

  $: myPlans = $plans.filter((plan) => plan.isCurrent || plan.status === "pending");
</script>

<svelte:head>
  <title>Account | {APP_NAME}</title>
</svelte:head>

{#if view === "main"}
  <section class="c:box mb-4 min-h-[188px]">
    <h1 class="pb-4 text-h5">Account</h1>
    <h3 class="pb-2 text-tiny text-light-02">Email address</h3>

    <Table isLoading={$isRefreshing} data={user.subscriber_email && [user.subscriber_email]} />
  </section>

  <section class="c:box mb-4 flex min-h-[288px] flex-col">
    <h2 class="pb-4 text-h5">Your plan subscriptions</h2>
    {#if myPlans.length}
      {#each myPlans as plan}
        <MyPlan
          {...plan}
          {handleRecovery}
          disableGoToAndPayments={$tooManyRecoveries === plan.orderId || !plan.details.browserSupport.isSupported}
          on:cancel={plan.status === "pending" ? cancelPlan : handleViewChange("cancel")}
          on:paymentClick={handlePaymentAction}
          on:cancelPending={cancelPlan}
          on:renew={handleRenew}
        />
      {/each}
    {:else if $isRefreshing}
      <Spinner />
    {:else}
      <div class="m-auto flex flex-col items-center self-center">
        <p class="pb-6">You don’t have any active plans.</p>
        <a href="/plans" class="c:btn c:btn--filled c:btn--large">Browse plans</a>
      </div>
    {/if}
  </section>

  <a
    href="https://support.brave.com/hc/en-us/requests/new?ticket_form_id=360003078831"
    class="c:btn c:btn--ghost c:btn--large w-full"
  >
    <svg class="o:icon c:btn__icon inline-block">
      <use href="/images/brave-icons.svg#trash" />
    </svg>
    Delete my account
  </a>
{:else if view === "cancel"}
  <CancelView {currentPlan} on:confirmCancel={cancelPlan} on:back={handleViewChange("main")} />
{:else if view === "cancelConfirmed"}
  <CancelConfirmedView on:returnToAccount={reloadAccount} />
{/if}
