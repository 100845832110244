<script>
  import { getFormattedPrice } from "$lib/utils";
  import { createEventDispatcher } from "svelte";
  import CancelLinkedAccount from "./CancelLinkedAccount.svelte";
  import Spinner from "./Spinner.svelte";

  export let currentPlan = {};

  let isLoading = false;
  const dispatch = createEventDispatcher();
  const emitClick = (type) => () => {
    isLoading = !isLoading;
    dispatch(type);
  };
</script>

<article class="c:box mb-4 flex min-h-[288px] flex-col">
  <h2 class="pb-4 text-h5">Cancel subscription</h2>
  {#if ["ios", "android"].includes(currentPlan.payment_processor)}
    <CancelLinkedAccount processor={currentPlan.payment_processor} />
  {:else}
    <div
      class="cancel-preview flex flex-col items-center gap-x-3 space-y-4 pb-4 md-sm:grid md-sm:items-stretch md-sm:gap-x-8 md-sm:space-y-0"
    >
      <article class="theme--dark w-full max-w-[261px] overflow-hidden rounded-lg bg-dark-02 px-6 pb-5 pt-0 text-white">
        <div
          class="success -mx-6 mb-6 bg-success-icon px-4 py-0.5 text-center text-sm font-semibold tracking-wide text-white"
        >
          Current
        </div>

        <div class="flex">
          <img
            class="mt-[6px] hidden max-w-max self-start pr-6 sm:block"
            width="64"
            src={`/icons/${currentPlan.icon}`}
            alt={`${currentPlan.name} icon`}
          />
          <div class="flex flex-grow flex-col justify-between">
            <header class="flex flex-col items-start">
              <h1 class="text-h6">{currentPlan.name}</h1>
              <span class="text-h6 font-semibold"
                >{getFormattedPrice(currentPlan.price_paid || currentPlan.price)}<span class="text-sm text-dark-02">/month</span></span
              >
            </header>
          </div>
        </div>
      </article>

      <svg
        class="my-auto rotate-90 transform self-center sm:transform-none"
        width="35"
        height="27"
        viewBox="0 0 35 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.273 14.9583L1.45833 14.9583C0.652918 14.9583 0 14.3054 0 13.5C0 12.6946 0.652918 12.0417 1.45833 12.0417L30.273 12.0417L20.3747 2.90494C19.7829 2.35865 19.746 1.43602 20.2923 0.844193C20.8385 0.252366 21.7612 0.215451 22.353 0.761742L34.218 11.7138C35.2607 12.6763 35.2607 14.3237 34.218 15.2862L22.353 26.2383C21.7612 26.7846 20.8385 26.7476 20.2923 26.1558C19.746 25.564 19.7829 24.6414 20.3747 24.0951L30.273 14.9583Z"
          fill="#868E96"
        />
      </svg>

      <article
        class="theme--dark flex w-full max-w-[261px] flex-col justify-start overflow-hidden rounded-lg bg-dark-02 px-6 pb-5 pt-0 text-white"
      >
        <div
          class="success -mx-6 mb-6 bg-gray-600 px-4 py-0.5 text-center text-sm font-semibold tracking-wide text-white"
        >
          New
        </div>

        <div class="my-auto flex items-center justify-center">
          <h1 class="text-h6">{currentPlan.freeVersion}</h1>
        </div>
      </article>
    </div>
    <section>
      <h3 class="pb-3 text-sm font-semibold">What happens next:</h3>
      <ul class="list-disc pl-4">
        <li class="pb-2 text-sm">
          Your subscription will end, and you will not be charged on the next billing period.
        </li>
        <li class="pb-2 text-sm">
          You will continue to have use of the product until the end of the current billing period.
        </li>
      </ul>
      <h3 class="pb-3 pt-6 text-sm font-semibold">
        After the current billing period, you will no longer have access to {currentPlan.name} or its benefits:
      </h3>
      <ul class="list-disc pl-4">
        {#each currentPlan.features as feature}
          <li class="pb-2 text-sm">{feature}</li>
        {/each}
      </ul>
    </section>
  {/if}
  <footer class="flex flex-col-reverse items-center justify-between pt-8 xxs:flex-row">
    <button class="c:btn c:btn--text mt-3 xxs:mt-0" on:click={emitClick("back")}>
      <svg class="o:icon mr-2 inline-block w-[10px]">
        <use href="/images/brave-icons.svg#chevron-left" />
      </svg>
      Back
    </button>
    {#if !["ios", "android"].includes(currentPlan.payment_processor)}
      <button
        class="c:btn c:btn--outline"
        on:click={emitClick("confirmCancel")}
        disabled={isLoading}
        class:loading={isLoading}
      >
        {#if isLoading}
          <Spinner fill="currentColor" delay={false} fillContainer={false} />
          Cancelling...
        {:else}
          Cancel subscription
        {/if}
      </button>
    {/if}
  </footer>
</article>

<style lang="scss">
  .cancel-preview {
    grid-template-columns: minmax(42%, 261px) min-content minmax(42%, 261px);
  }
</style>
