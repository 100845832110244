import * as rewards from "@brave-intl/skus-sdk";
import wasmURL from "@brave-intl/skus-sdk/skus_sdk_bg.wasm?url";
import { hasBrowserSkusService, unloadUser } from "./utils";
import { STRIPE_PUB_KEY, ENV } from "./Env";
import planDescriptions from "./plans";
import { deleteCredentials, subscribe, resubscribe } from "./subscription-service";

// @ts-ignore
rewards.setWASMImportURL(wasmURL);

const initPayments = async () => {
  // @ts-ignore
  const stripe = Stripe(STRIPE_PUB_KEY);

  // @ts-ignore
  const sdk = await rewards.initialize(ENV);

  /**
   * Redirect user to Stripe checkout
   * @param checkoutSessionId
   */
  const checkout = (checkoutSessionId: string): void => {
    stripe.redirectToCheckout({
      sessionId: checkoutSessionId,
    });
  };

  /**
   * Trigger purchase flow that culminates in redirection to Stripe checkout
   * @param {string} productId UUID for product
   */
  const purchasePlanIfNecessary = async (productId: string, subscriptionId?: string): Promise<void> => {
    try {
      unloadUser();

      const subscription = subscriptionId ? await resubscribe(productId, subscriptionId) : await subscribe(productId);

      const order = await sdk.refresh_order(subscription.order_id);

      if (order.status !== "paid") {
        checkout(order.metadata.stripe_checkout_session_id);
      }
    } catch (e) {
      console.log(e.message)
      if (e.message?.startsWith("Already Subscribed")) {
        // TODO: decide if this should change to client side routing
        location.replace(`/account/?intent=recover&product_id=${productId}`);
      }
    }
  };

  /**
   * Fetch credentials for product
   * @param {string} orderId UUID for order
   */
  const provisionOrder = async (order: Plan): Promise<void> => {
    if (order.status === "paid") {
      await sdk.fetch_order_credentials(order.orderId);
    }
  };

  /**
   * Recover credentials for product (on new device, or after clearing local storage)
   * @param {string} orderId UUID for order
   */
  const recoverCredsIfRequired = async (orderId: string, subscriptionId: string): Promise<void> => {
    const order = await sdk.refresh_order(orderId);
    const summary = await sdk.credential_summary(order.location);
    if (["paid", "canceled"].includes(order.status)) {
      if (!summary || !summary.expires_at) {
        await deleteCredentials(subscriptionId);
        await sdk.fetch_order_credentials(orderId);
      } else {
        // TODO: Try again tomorrow
      }
    } else {
      throw new Error("Order not paid.");
    }
  };

  /**
   * Recover credentials for product (on new device, or after clearing local storage)
   * @param {string} orderId UUID for order
   */
  const deleteRemoteCredsIfNeccessary = async (orderId: string, subscriptionId: string): Promise<void> => {
    const order = await sdk.refresh_order(orderId);
    if (["paid", "canceled"].includes(order.status)) {
      await deleteCredentials(subscriptionId);
    } else {
      throw new Error("Order not paid.");
    }
  };

  /**
   * Get order details from payments server
   * @param orderId string
   * @returns object
   */
  const getOrderDetails = async (orderId: string): Promise<object> => {
    return await sdk.refresh_order(orderId);
  };

  /**
   * Check to see if credentials are present
   * @param orderLocation string
   * @returns object
   */
  const checkForCredentials = async (plan: Plan): Promise<boolean> => {
    try {
      const summary = await sdk.credential_summary(plan.productDomain);
      return summary && summary.active;
    } catch (e) {
      if (e.message === "Error parsing JSON response") {
        await provisionOrder(plan);

        const summary = await sdk.credential_summary(plan.productDomain);
        return summary && summary.active;
      }
    }
  };

  /**
   * Get user's status for all plans and merge with plan descriptions
   * @param {object} user - current user
   * @returns {Promise} Promise represents array of plans with status for user
   */
  const refreshPlans = (user: User): Promise<Plan[]> => {
    return Promise.all(
      Object.values(planDescriptions)
        .sort((a, b) => (a.weight || 10000) - (b.weight || 10000))
        .map(async (plan: PlanDetails) => {
          const newPlan: Plan = { details: plan };
          const myPlan = user.subscriptions.find((v) => v.product_id === plan.id);
          if (myPlan) {
            const {
              status,
              expires_at,
              last_paid_at,
              location: productDomain,
              items: [{ price }],
              metadata: { stripe_checkout_session_id, payment_processor },
            } = await sdk.refresh_order(myPlan.order_id);

            newPlan.isExpired = expires_at && new Date(expires_at).getTime() < Date.now();
            newPlan.isCanceledFromPending = status === "canceled" && !expires_at && !last_paid_at;
            newPlan.isCurrent = expires_at !== null && !newPlan.isExpired && !newPlan.isCanceledFromPending;
            newPlan.pricePaid = price;
            newPlan.status = status;
            newPlan.orderId = myPlan.order_id;
            newPlan.expiresAt = expires_at;
            newPlan.checkoutSession = stripe_checkout_session_id;
            newPlan.subscriptionId = myPlan.subscription_id;
            newPlan.productDomain = productDomain;
            newPlan.currentBrowserSupported = true;
            newPlan.paymentProcessor = payment_processor;

            if (newPlan.details.usesNativeSDK) {
              newPlan.credentialsPresent = await checkForCredentials(newPlan);
              newPlan.currentBrowserSupported = hasBrowserSkusService();
            }
          }
          return newPlan;
        })
    );
  };

  return {
    sdk,
    purchasePlanIfNecessary,
    provisionOrder,
    refreshPlans,
    checkout,
    getOrderDetails,
    recoverCredsIfRequired,
    deleteRemoteCredsIfNeccessary,
  } as const;
};

let psPromise: ReturnType<typeof initPayments>;
export const getPsPromise = () => {
  if (!psPromise) {
    psPromise = initPayments();
  }
  return psPromise;
};
