<script>
  import { page } from "$app/stores";
  $: pathname = $page.url.pathname;

  export let to;
  export let icon;

  // Normalize `to` to include trailing slash
  to = to.replace(/\/$/).concat("/");
</script>

<a href={to} class="nav-item" class:-active={pathname === to}>
  <svg class="o:icon inline-block">
    <use href={`/images/brave-icons.svg#${icon}`} />
  </svg>
  <slot />
</a>

<style lang="scss">
  .nav-item {
    @apply block;
    @apply px-4 py-2;
    @apply -mx-4;
    @apply text-black;
    @apply flex items-center;

    &:hover {
      @apply no-underline;
      @apply text-interactive-05;
    }

    .o\:icon {
      margin-right: 10px;
    }

    &:hover .o\:icon {
      fill: theme("colors.interactive-05");
    }

    &.-active {
      font-weight: 600;
      color: #9736c4;
      background: linear-gradient(122.53deg, #4c54d2 0%, #bf14a2 56.25%, #f73a1c 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      &::before {
        @apply absolute left-0 top-0;
        content: "";
        display: block;
        height: 100%;
        width: 4px;
        background: linear-gradient(122.53deg, #4c54d2 0%, #bf14a2 56.25%, #f73a1c 100%);
        border-radius: 0px 2px 2px 0px;
      }

      .o\:icon {
        fill: url("#active-gradient");
      }
    }
  }
</style>
