<script>
  import Spinner from "./Spinner.svelte";

  export let isLoading = false;
  export let data = [];
</script>

{#if isLoading}
  <div>
    <div class="row flex min-h-[50px]">
      <Spinner justify="start" />
    </div>
  </div>
{:else if data && data.length}
  <div>
    {#each data as datum}
      <div class="row flex">
        {#if datum instanceof Object}
          {#each Object.values(datum) as value}
            <div>{value}</div>
          {/each}
        {:else}
          <div>{datum}</div>
        {/if}
      </div>
    {/each}
  </div>
{/if}

<style lang="scss">
  .row {
    @apply text-sm;
    padding: 14px 0;
    border-bottom: 1px solid #e8e8e8;
  }
  .row:first-child {
    border-top: 1px solid #e8e8e8;
  }
</style>
