<script>
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  const emitClick = (type) => () => {
    dispatch(type);
  };
</script>

<article class="c:box mb-4 flex flex-col items-center">
  <svg class="o:icon check-mark">
    <use href="/images/brave-icons.svg#check-circle" />
  </svg>
  <p class="pt-4 text-center text-lg">Your plan has been canceled.</p>
  <button class="c:btn c:btn--outline mt-9 w-full" on:click={emitClick("returnToAccount")}> Return to account </button>
</article>

<style lang="scss">
  .check-mark {
    --color: theme("colors.success-icon");
    --size: 64px;
  }
</style>
